import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";

import { catalogueStyles } from "../Detail";
import { syncCatToProducts } from "views/Catalogues/api";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import DomainIcon from "../../assets/domain.png";
import LINKit from "views/Catalogues/List/LINKit";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Grid } from "@material-ui/core/index";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DetailHeader = ({
  isEditable,
  onSubmit,
  setIsEditable,
  setShowDeleteProducts,
  setSelectedProducts,
}) => {
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;
  const [expiryUrl, setExpiryUrl] = useState({});
  const [catalogues, setCatalogues] = useState([]);
  const [validUsers, setValidUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (values._id) {
      setCatalogues([{ _id: values._id, name: values.name }]);
    }
    if (values._id && values.includedUsers?.length) {
      setValidUsers(values.includedUsers.filter((user) => user._id));
    }
  }, [values.name, values._id, values.includedUsers]);

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.catalogues;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        setSelectedProducts([]);
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    // {
    // // xs: 12,
    // name: "includedUsers",
    // label: "E-Commerce Authorization",
    // fieldType: "dropdown",
    // multiple: true,
    // render: "firstName",
    // renderFunc: (option) =>
    //   (option?.firstName || "") +
    //   " " +
    //   (option?.lastName || "") +
    //   (option?.phone ? ` (${option?.phone})` : ""),
    // extraOptions: 3,
    // options: users || [],
    // onChange: (_, value) => {
    //   const isSelectAll = value?.find(
    //     (value) => value.firstName === enums.keywords.selectAll
    //   );

    //   if (isSelectAll) {
    //     let allUsers = users ? [...users] : [];

    //     if (values.includedUsers?.length === users.length - 3) {
    //       allUsers = [];
    //     } else {
    //       allUsers.shift();
    //       allUsers.shift();
    //       allUsers.shift();
    //     }

    //     setValue("includedUsers", allUsers);
    //   }
    // },
    // getOptionLabel: (opt) =>
    //   (opt?.firstName || "") +
    //   " " +
    //   (opt?.lastName || "") +
    //   (opt?.phone ? ` (${opt?.phone})` : ""),
    // groupBy: (option) => option?.role,
    // getOptionSelected: (option, value) =>
    //   (option?.firstName || "") +
    //     " " +
    //     (option?.lastName || "") +
    //     (option?.phone ? ` (${option?.phone})` : "") ===
    //   (value?.firstName || "") +
    //     " " +
    //     (value?.lastName || "") +
    //     (value?.phone ? ` (${value?.phone})` : ""),
    // renderOption: enums.keywords.selectAll,
    // },
    // {
    //   label: "Public sharing URL",
    //   copyToClipboard: true,
    //   xs: 12,
    //   hidden: !values._id || isEditable,
    //   value: `${commonConfig.hyperLinks["e-creatives"]}#/public/${
    //     enums.models.catalogues
    //   }/${values.client?._id || values.client}/${values._id}`,
    // },
    // {
    //   xs: 8,
    //   name: "products",
    //   label: "Products",
    //   fieldType: "dropdown",
    //   multiple: true,
    //   render: "styleNo",
    //   extraOptions: 1,
    //   options: products || [],
    //   onChange: (_, value) => {
    //     const isSelectAll = value?.find(
    //       (value) => value.styleNo === enums.keywords.selectAll
    //     );

    //     const isCat = uniqueCats.find((cat) =>
    //       value?.find((prod) => prod._id === cat._id)
    //     );

    //     if (isSelectAll) {
    //       let allProducts = products ? [...products] : [];
    //       allProducts = allProducts.filter((prod) =>
    //         uniqueCats?.find((cat) => cat?.name === prod?.category?.name)
    //       );

    //       if (
    //         values.products?.length ===
    //         products.length - uniqueCats?.length - 1
    //       ) {
    //         allProducts = [];
    //       }

    //       setValue("products", allProducts);
    //     } else if (isCat) {
    //       let allProducts = [...values.products] || [];
    //       let allCatProducts = products
    //         ? [
    //             ...products?.filter(
    //               (prod) => prod?.category?._id === isCat?._id
    //             ),
    //           ]
    //         : [];

    //       const existingCatProds =
    //         values.products?.filter(
    //           (prod) => prod?.category?._id === isCat?._id
    //         ) || [];

    //       if (existingCatProds?.length === allCatProducts.length) {
    //         allProducts = allProducts.filter(
    //           (prd) => prd?.category?._id !== isCat?._id
    //         );
    //       } else {
    //         allProducts = uniqueArray([...allProducts, ...allCatProducts]);
    //       }

    //       setValue("products", allProducts);
    //     }
    //   },
    //   getOptionLabel: (opt) => opt?.styleNo || "",
    //   // groupBy: (option) => option?.category?.name,
    //   getOptionSelected: (option, value) => option?.styleNo === value?.styleNo,
    //   renderOption: (option, state) => {
    //     const values = formMethods.getValues();
    //     const isCat = uniqueCats.find((cat) => cat._id === option?._id);

    //     if (option.styleNo === enums.keywords?.selectAll || isCat) {
    //       const sameCats = values.products?.filter(
    //         (prod) =>
    //           products?.find((cp) => cp?._id === prod?._id)?.category?._id ===
    //           isCat?._id
    //       );

    //       if (isCat) {
    //         if (sameCats.length === isCat?.count) {
    //           state.selected = true;
    //         } else {
    //           state.selected = false;
    //         }
    //       } else if (
    //         values.products?.length ===
    //         products?.length - uniqueCats?.length - 1
    //       ) {
    //         state.selected = true;
    //       } else {
    //         state.selected = false;
    //       }

    //       return (
    //         <React.Fragment>
    //           <Checkbox
    //             icon={icon}
    //             checkedIcon={checkedIcon}
    //             // style={{}}
    //             checked={state.selected}
    //           />
    //           {option?.styleNo}
    //         </React.Fragment>
    //       );
    //     } else {
    //       return <React.Fragment>{option?.styleNo}</React.Fragment>;
    //     }
    //   },
    // },
  ];

  const handleSync = async () => {
    try {
      const { existing, updated, removed } = await syncCatToProducts(values);
      setValue("frontend.sync", new Date()); // updated value is not used, just to give values.frontend.sync in useEffect dependancy, this is done.
      CustomEventEmitter.emit(
        "alert_success",
        `Products\nExisting: ${existing}\nRemoved: ${removed}\nUpdated: ${updated}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const links = [
    {
      name: "Catalogues",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Remove",
      iconName: "remove",
      hidden:
        isEditable ||
        !values.code ||
        values.workflow !== enums.catalogueWorkflow.individual,
      onClick: () => {
        setIsEditable(true);
        setShowDeleteProducts(true);
      },
    },
    {
      label:
        values.workflow === enums.catalogueWorkflow.individual
          ? "Add More"
          : "Edit",
      iconName:
        values.workflow === enums.catalogueWorkflow.individual ? "add" : "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
        setShowDeleteProducts(false);
      },
    },

    {
      label: "Sync Products",
      iconName: "sync",
      hidden:
        !values.code ||
        values.workflow !== enums.catalogueWorkflow.bulk ||
        isEditable,
      onClick: handleSync,
    },
    {
      hidden: isEditable,
      label: "Generate Link",
      iconSvg: DomainIcon,
      iconSvgStyle: { height: "32px", width: "32px" },
      onClick: handleOpenDialog,
      tooltip: "Generate Link",
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Catalogues"} actions={actions} />
      {expiryUrl?.url ? (
        <Grid
          container
          style={{ cursor: "copy" }}
          onClick={() => {
            navigator.clipboard.writeText(expiryUrl?.url);
            CustomEventEmitter.emit(
              "alert_success",
              `Copied to Clipboard, URL expires in 🎮 ${expiryUrl?.expiresIn} 🎲`
            );
          }}
        >
          <Grid item xs={11}>
            <pre style={{ color: "#007bff", textDecoration: "underline" }}>
              Copy expiry Link for{" "}
              {(expiryUrl?.catalogues || []).map((cat) => cat.name).join(", ")}{" "}
              - {expiryUrl.url.split("/")[expiryUrl.url.split("/").length - 1]}
              {"  "}
            </pre>
          </Grid>

          <Grid item xs={1}>
            <MyHoverIcons
              muiIcon={
                <FileCopyOutlinedIcon
                  style={{
                    fontSize: "42px",
                    marginTop: "12px",
                    cursor: "copy",
                  }}
                />
              }
              muiIconHover={
                <FileCopyIcon
                  style={{
                    fontSize: "42px",
                    marginTop: "12px",
                    cursor: "copy",
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      ) : null}
      <LINKit
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        catalogues={catalogues}
        setCatalogues={setCatalogues}
        detailShared={true}
        validUsers={validUsers}
        setValidUsers={setValidUsers}
        setExpiryUrl={setExpiryUrl}
      />
      {values.code ? null : (
        <GeneralSection
          style={catalogueStyles.section}
          fields={entityFields}
          title="Information"
          isEditable={isEditable}
          values={values}
        ></GeneralSection>
      )}
    </>
  );
};

export default DetailHeader;
