import GeneralSection from "@ui/ComponentUtils/GeneralSection";
import Header from "@ui/ComponentUtils/Header";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import { uniqueDesigns } from "@ui/ComponentUtils/blueprintAPIs";
import { Checkbox } from "@material-ui/core/index";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DetailHeader = (props) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const values = formMethods.watch();
  const { setValue } = formMethods;
  const [products, setProducts] = useState([]);

  const [uniqueCats, setUniqueCats] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const model = enums.models.groups;

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      let res = await uniqueDesigns({
        // criteria: {
        //   stockStatus: enums.stockStatus.inventory,
        // },
      });

      res.sort((a, b) => a.category?.name?.localeCompare(b.category?.name));

      let currentCat = "",
        groupedProducts = [],
        tempUniqueCats = [];
      res.map((prod) => {
        if (currentCat !== prod?.category?.name) {
          currentCat = prod?.category?.name;

          tempUniqueCats.push({
            name: currentCat,
            count: 1,
            _id: prod?.category?._id,
          });
          return groupedProducts.push(
            { styleNo: currentCat, _id: prod?.category?._id },
            prod
          );
        }
        tempUniqueCats.find((cat, i) => {
          if (cat?._id === prod?.category?._id) {
            tempUniqueCats[i].count += 1;
          }
        });

        return groupedProducts.push(prod);
      });

      setUniqueCats(tempUniqueCats || []);
      setProducts(
        [{ styleNo: enums.keywords.selectAll }, ...groupedProducts] || []
      );
    })();
  }, []);

  const entityFields = [
    {
      name: "name",
      label: "Name",
      fieldType: "textField",
    },
    {
      xs: 8,
      name: "products",
      label: "Products",
      fieldType: "dropdown",
      multiple: true,
      render: "styleNo",
      extraOptions: 1,
      options: products || [],
      onChange: (_, value) => {
        const isSelectAll = value?.find(
          (value) => value.styleNo === enums.keywords.selectAll
        );

        const isCat = uniqueCats.find((cat) =>
          value?.find((prod) => prod._id === cat._id)
        );

        if (isSelectAll) {
          let allProducts = products ? [...products] : [];
          allProducts = allProducts.filter((prod) =>
            uniqueCats?.find((cat) => cat?.name === prod?.category?.name)
          );

          if (
            values.products?.length ===
            products.length - uniqueCats?.length - 1
          ) {
            allProducts = [];
          }

          setValue("products", allProducts);
        } else if (isCat) {
          let allProducts = [...values.products] || [];
          let allCatProducts = products
            ? [
                ...products?.filter(
                  (prod) => prod?.category?._id === isCat?._id
                ),
              ]
            : [];

          const existingCatProds =
            values.products?.filter(
              (prod) => prod?.category?._id === isCat?._id
            ) || [];

          if (existingCatProds?.length === allCatProducts.length) {
            allProducts = allProducts.filter(
              (prd) => prd?.category?._id !== isCat?._id
            );
          } else {
            const finalArray = [...allProducts, ...allCatProducts];
            const uniqueArray = [];

            finalArray.map((prod) => {
              if (
                !uniqueArray.find((uProd) => uProd.styleNo === prod.styleNo)
              ) {
                uniqueArray.push(prod);
              }
            });
            allProducts = uniqueArray;
          }

          setValue("products", allProducts);
        }
      },
      getOptionLabel: (opt) => opt?.styleNo || "",
      // groupBy: (option) => option?.category?.name,
      getOptionSelected: (option, value) => option?.styleNo === value?.styleNo,
      renderOption: (option, state) => {
        const values = formMethods.getValues();
        const isCat = uniqueCats.find((cat) => cat._id === option?._id);

        if (option.styleNo === enums.keywords?.selectAll || isCat) {
          const sameCats = values.products?.filter(
            (prod) =>
              products?.find((cp) => cp?._id === prod?._id)?.category?._id ===
              isCat?._id
          );

          if (isCat) {
            if (sameCats.length === isCat?.count) {
              state.selected = true;
            } else {
              state.selected = false;
            }
          } else if (
            values.products?.length ===
            products?.length - uniqueCats?.length - 1
          ) {
            state.selected = true;
          } else {
            state.selected = false;
          }

          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                // style={{}}
                checked={state.selected}
              />
              {option?.styleNo}
            </React.Fragment>
          );
        } else {
          return <React.Fragment>{option?.styleNo}</React.Fragment>;
        }
      },
    },
  ];

  const links = [
    {
      name: "Specials",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: values.name,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  return (
    <>
      <Header links={links} pageTitle={"Specials"} actions={actions} />

      <GeneralSection
        // actions={sectionActions}
        fields={entityFields}
        title="Information"
        isEditable={isEditable}
        values={values}
      />
    </>
  );
};

export default DetailHeader;
